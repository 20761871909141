// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Js_array = require("rescript/lib/js/js_array.js");
var Property = require("./Property.bs.js");
var DateFnsTz = require("../../common/DateFns/DateFnsTz.bs.js");
var ExpiryInfo = require("./ExpiryInfo.bs.js");
var AreaSummary = require("../graphql/AreaSummary.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Coordinates = require("./Coordinates.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var GraphQLConverter = require("../../common/GraphQLConverter.bs.js");
var Types__Attachment = require("../../common/types/Types__Attachment.bs.js");
var StandardizedAddress = require("../graphql/StandardizedAddress.bs.js");
var Types__Clients__Contact = require("./Types__Clients__Contact.bs.js");

function decodeOptionalUTCDate(x) {
  return Core__Option.map(x, GraphQLConverter.Helpers.parseUTCDateFromFloat);
}

function decodeOptionalCalendarDate(x) {
  return Core__Option.map(x, GraphQLConverter.Helpers.decodeCalendarDate);
}

function decodeMessage(obj) {
  var partial_arg = GraphQLConverter.Helpers.someOk(obj.createdAt, "createAt was None");
  var partial_arg$1 = GraphQLConverter.Helpers.some(obj.subject)("subject was None");
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (subject, createdAt) {
                return {
                        id: obj.id,
                        subject: subject,
                        body: obj.body,
                        createdAt: createdAt
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
            });
}

function decodeMessages(param) {
  return GraphQLConverter.Helpers.decodeRequired("messages was None", (function (param) {
                return GraphQLConverter.Helpers.decodeToNonEmptyList((function (param) {
                              return GraphQLConverter.Helpers.decodeRequired("message was None", decodeMessage, param);
                            }), param);
              }), param);
}

function flattenFragment(obj) {
  return obj.messages;
}

var ppx_printed_query = "fragment rentalPropertyMessages on RentalProperty   {\nmessages  {\nid  \nsubject  \nbody  \ncreatedAt  \n}\n\n}\n";

function parse(value) {
  var value$1 = value["messages"];
  var tmp = {
    messages: decodeMessages(!(value$1 == null) ? Js_array.map((function (value) {
                  if (value == null) {
                    return ;
                  }
                  var value$1 = Js_json.decodeObject(value);
                  var tmp;
                  if (value$1 !== undefined) {
                    var value$2 = Js_dict.get(value$1, "id");
                    var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
                    var value$3 = Js_dict.get(value$1, "subject");
                    var field_subject = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
                    var value$4 = Js_dict.get(value$1, "body");
                    var field_body = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
                    var value$5 = Js_dict.get(value$1, "createdAt");
                    var field_createdAt = value$5 !== undefined ? Core__Option.map(!(value$5 == null) ? value$5 : undefined, GraphQLConverter.Helpers.parseUTCDateFromFloat) : Js_exn.raiseError("Unexpected GraphQL query response");
                    tmp = {
                      id: field_id,
                      subject: field_subject,
                      body: field_body,
                      createdAt: field_createdAt
                    };
                  } else {
                    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                  }
                  return tmp;
                }), value$1) : undefined)
  };
  return tmp.messages;
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var RentalPropertyMessages = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "rentalPropertyMessages",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "fragment forSalePropertyMessages on Property   {\nmessages  {\nid  \nsubject  \nbody  \ncreatedAt  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value["messages"];
  var tmp = {
    messages: decodeMessages(!(value$1 == null) ? Js_array.map((function (value) {
                  if (value == null) {
                    return ;
                  }
                  var value$1 = Js_json.decodeObject(value);
                  var tmp;
                  if (value$1 !== undefined) {
                    var value$2 = Js_dict.get(value$1, "id");
                    var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
                    var value$3 = Js_dict.get(value$1, "subject");
                    var field_subject = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
                    var value$4 = Js_dict.get(value$1, "body");
                    var field_body = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
                    var value$5 = Js_dict.get(value$1, "createdAt");
                    var field_createdAt = value$5 !== undefined ? Core__Option.map(!(value$5 == null) ? value$5 : undefined, GraphQLConverter.Helpers.parseUTCDateFromFloat) : Js_exn.raiseError("Unexpected GraphQL query response");
                    tmp = {
                      id: field_id,
                      subject: field_subject,
                      body: field_body,
                      createdAt: field_createdAt
                    };
                  } else {
                    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                  }
                  return tmp;
                }), value$1) : undefined)
  };
  return tmp.messages;
}

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var ForSalePropertyMessages = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  name: "forSalePropertyMessages",
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var Messages = {
  decodeMessage: decodeMessage,
  decodeMessages: decodeMessages,
  flattenFragment: flattenFragment,
  RentalPropertyMessages: RentalPropertyMessages,
  ForSalePropertyMessages: ForSalePropertyMessages
};

function decode(obj) {
  var match = Core__Option.getOr(obj.hideAddress, true);
  var match$1 = obj.rentalAddress;
  if (match) {
    return {
            TAG: "Ok",
            _0: {
              NAME: "Private",
              VAL: match$1
            }
          };
  } else if (match$1 !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              NAME: "Public",
              VAL: match$1
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: GraphQLConverter.Helpers.makeError("public address was None")
          };
  }
}

var ppx_printed_query$2 = "fragment rentalAddress on RentalProperty   {\nrentalAddress: userEnteredAddress  \nhideAddress  \n}\n";

function parse$2(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "rentalAddress");
    var field_rentalAddress = value$2 !== undefined && !(value$2 == null) ? value$2 : undefined;
    var value$3 = Js_dict.get(value$1, "hideAddress");
    var field_hideAddress = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    tmp = {
      rentalAddress: field_rentalAddress,
      hideAddress: field_hideAddress
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode(tmp);
}

var name = "rentalAddress";

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var RentalAddress = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  name: name,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function toString(x) {
  if (x.NAME === "Public") {
    return Caml_option.some(x.VAL);
  } else {
    return x.VAL;
  }
}

var RentalAddress$1 = {
  decode: decode,
  RentalAddress: RentalAddress,
  toString: toString,
  Fragment: undefined
};

function decode$1(obj) {
  var match = obj.priceRange;
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: GraphQLConverter.Helpers.makeError("priceRange was None")
          };
  }
  if (match) {
    var partial_arg = GraphQLConverter.Helpers.some(obj.maxPrice)("maxPrice was None");
    var partial_arg$1 = GraphQLConverter.Helpers.some(obj.minPrice)("minPrice was None");
    var partial_arg$2 = function (param) {
      return GraphQLConverter.Helpers.flatMap((function (param) {
                    return GraphQLConverter.Helpers.source(obj, param);
                  }), partial_arg$1, param);
    };
    return GraphQLConverter.Helpers.run(function (min, max) {
                  return {
                          NAME: "Monthly",
                          VAL: {
                            NAME: "PriceRange",
                            VAL: [
                              min,
                              max
                            ]
                          }
                        };
                })(function (param) {
                return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
              });
  }
  var partial_arg$3 = GraphQLConverter.Helpers.some(obj.price)("price was None");
  return GraphQLConverter.Helpers.run(function (price) {
                return {
                        NAME: "Monthly",
                        VAL: {
                          NAME: "Price",
                          VAL: price
                        }
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap((function (param) {
                            return GraphQLConverter.Helpers.source(obj, param);
                          }), partial_arg$3, param);
            });
}

var ppx_printed_query$3 = "fragment rentalPrice on RentalProperty   {\nprice  \nminPrice  \nmaxPrice  \npriceRange  \n}\n";

function parse$3(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "price");
    var field_price = value$2 !== undefined && !(value$2 == null) ? value$2 : undefined;
    var value$3 = Js_dict.get(value$1, "minPrice");
    var field_minPrice = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    var value$4 = Js_dict.get(value$1, "maxPrice");
    var field_maxPrice = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
    var value$5 = Js_dict.get(value$1, "priceRange");
    var field_priceRange = value$5 !== undefined && !(value$5 == null) ? value$5 : undefined;
    tmp = {
      price: field_price,
      minPrice: field_minPrice,
      maxPrice: field_maxPrice,
      priceRange: field_priceRange
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$1(tmp);
}

var name$1 = "rentalPrice";

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var RentalPrice = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  name: name$1,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

var Price = {
  decode: decode$1,
  RentalPrice: RentalPrice,
  Fragment: undefined
};

function decode$2(obj) {
  var partial_arg = GraphQLConverter.Helpers.someOk(obj.availableFrom, "availableFrom was None");
  var partial_arg$1 = GraphQLConverter.Helpers.some(obj.leaseLength)("leaseLength was None");
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (length, availableFrom) {
                return {
                        length: length,
                        availableFrom: availableFrom
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
            });
}

var ppx_printed_query$4 = "fragment lease on RentalProperty   {\navailableFrom: availableFromV2  \nleaseLength  \n}\n";

function parse$4(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "availableFrom");
    var field_availableFrom = value$2 !== undefined ? Core__Option.map(!(value$2 == null) ? value$2 : undefined, GraphQLConverter.Helpers.decodeCalendarDate) : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$3 = Js_dict.get(value$1, "leaseLength");
    var field_leaseLength;
    if (value$3 !== undefined && !(value$3 == null)) {
      var tmp$1;
      switch (value$3) {
        case "LONG" :
            tmp$1 = "LONG";
            break;
        case "SHORT" :
            tmp$1 = "SHORT";
            break;
        default:
          tmp$1 = "SHORT_OR_LONG";
      }
      field_leaseLength = tmp$1;
    } else {
      field_leaseLength = undefined;
    }
    tmp = {
      leaseLength: field_leaseLength,
      availableFrom: field_availableFrom
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$2(tmp);
}

var name$2 = "lease";

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

var Lease = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  name: name$2,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4
};

var Lease$1 = {
  decode: decode$2,
  Lease: Lease,
  Fragment: undefined
};

function decode$3(property) {
  var partial_arg = GraphQLConverter.Helpers.some(property.userSelectedArea)("userSelectedArea was None");
  var partial_arg$1 = GraphQLConverter.Helpers.someOk(property.standardizedAddress, "standardizedAddress was None");
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(property, param);
                }), partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (a, u) {
                return Property.DisplayLocation.make(a, u, property.displayNeighborhood);
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
            });
}

var ppx_printed_query$5 = "fragment RentalDisplayLocation on RentalProperty   {\nuserSelectedArea  {\n..." + AreaSummary.AreaSummary.name + "   \n}\n\ndisplayNeighborhood  {\n..." + AreaSummary.AreaSummary.name + "   \n}\n\nstandardizedAddress  {\n..." + StandardizedAddress.Fragments.StandardizedAddress.name + "   \n}\n\n}\n" + AreaSummary.AreaSummary.query + StandardizedAddress.Fragments.StandardizedAddress.query;

function parse$5(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "userSelectedArea");
    var field_userSelectedArea = value$2 !== undefined && !(value$2 == null) ? AreaSummary.AreaSummary.parse(value$2) : undefined;
    var value$3 = Js_dict.get(value$1, "displayNeighborhood");
    var field_displayNeighborhood = value$3 !== undefined && !(value$3 == null) ? AreaSummary.AreaSummary.parse(value$3) : undefined;
    var value$4 = Js_dict.get(value$1, "standardizedAddress");
    var field_standardizedAddress = value$4 !== undefined && !(value$4 == null) ? Curry._1(StandardizedAddress.Fragments.StandardizedAddress.parse, value$4) : undefined;
    tmp = {
      userSelectedArea: field_userSelectedArea,
      displayNeighborhood: field_displayNeighborhood,
      standardizedAddress: field_standardizedAddress
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$3(tmp);
}

var name$3 = "RentalDisplayLocation";

function ret_type$5(f) {
  return {};
}

var MT_Ret$5 = {};

var RentalDisplayLocation = {
  ppx_printed_query: ppx_printed_query$5,
  query: ppx_printed_query$5,
  parse: parse$5,
  name: name$3,
  ret_type: ret_type$5,
  MT_Ret: MT_Ret$5
};

var RentalLocation = {
  decode: decode$3,
  RentalDisplayLocation: RentalDisplayLocation,
  Fragment: undefined
};

function getAttributes(x) {
  return x._0;
}

function decode$4(obj) {
  var match = obj.rentalPropertyType;
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: GraphQLConverter.Helpers.makeError("propertyType was None")
          };
  }
  var partial_arg = GraphQLConverter.Helpers.some(obj.baths)("baths was None");
  var partial_arg$1 = GraphQLConverter.Helpers.some(obj.beds)("beds was None");
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (beds, baths) {
                if (match === "SINGLE_FAMILY") {
                  return {
                          TAG: "SingleFamily",
                          _0: {
                            beds: beds,
                            baths: baths,
                            halfBaths: obj.halfBaths,
                            livingArea: obj.livingArea,
                            lotSize: obj.lotSize
                          }
                        };
                } else {
                  return {
                          TAG: "Condo",
                          _0: {
                            beds: beds,
                            baths: baths,
                            halfBaths: obj.halfBaths,
                            livingArea: obj.livingArea,
                            lotSize: obj.lotSize
                          }
                        };
                }
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
            });
}

var ppx_printed_query$6 = "fragment RentalAttributes on RentalProperty   {\nrentalPropertyType: propertyType  \nbeds  \nbaths  \nhalfBaths  \nlivingArea  \nlotSize  \n}\n";

function parse$6(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "rentalPropertyType");
    var field_rentalPropertyType;
    if (value$2 !== undefined && !(value$2 == null)) {
      var tmp$1 = value$2 === "SINGLE_FAMILY" ? "SINGLE_FAMILY" : "CONDO";
      field_rentalPropertyType = tmp$1;
    } else {
      field_rentalPropertyType = undefined;
    }
    var value$3 = Js_dict.get(value$1, "beds");
    var field_beds = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    var value$4 = Js_dict.get(value$1, "baths");
    var field_baths = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
    var value$5 = Js_dict.get(value$1, "halfBaths");
    var field_halfBaths = value$5 !== undefined && !(value$5 == null) ? value$5 : undefined;
    var value$6 = Js_dict.get(value$1, "livingArea");
    var field_livingArea = value$6 !== undefined && !(value$6 == null) ? value$6 : undefined;
    var value$7 = Js_dict.get(value$1, "lotSize");
    var field_lotSize = value$7 !== undefined && !(value$7 == null) ? value$7 : undefined;
    tmp = {
      rentalPropertyType: field_rentalPropertyType,
      beds: field_beds,
      baths: field_baths,
      halfBaths: field_halfBaths,
      livingArea: field_livingArea,
      lotSize: field_lotSize
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$4(tmp);
}

var name$4 = "RentalAttributes";

function ret_type$6(f) {
  return {};
}

var MT_Ret$6 = {};

var RentalAttributes = {
  ppx_printed_query: ppx_printed_query$6,
  query: ppx_printed_query$6,
  parse: parse$6,
  name: name$4,
  ret_type: ret_type$6,
  MT_Ret: MT_Ret$6
};

var RentalAttributes$1 = {
  getAttributes: getAttributes,
  decode: decode$4,
  RentalAttributes: RentalAttributes,
  Fragment: undefined
};

function make(status, expiryInfo) {
  var aboutToExpire = ExpiryInfo.aboutToExpire(expiryInfo);
  if (status === "WITHDRAWN") {
    return "Withdrawn";
  } else if (status === "REMOVED") {
    return "Removed";
  } else if (status === "EXPIRED") {
    return "Expired";
  } else if (status === "RENTED") {
    return "Rented";
  } else if (aboutToExpire) {
    return {
            TAG: "Expiring",
            _0: ExpiryInfo.daysToExpiration(expiryInfo)
          };
  } else {
    return "Active";
  }
}

function toStatusT(x) {
  if (typeof x === "object") {
    return {
            NAME: "EXPIRING",
            VAL: x._0
          };
  }
  switch (x) {
    case "Active" :
        return "ACTIVE";
    case "Withdrawn" :
        return "WITHDRAWN";
    case "Rented" :
        return "RENTED";
    case "Expired" :
        return "EXPIRED";
    case "Removed" :
        return "REMOVED";
    
  }
}

var ListingStatus = {
  make: make,
  toStatusT: toStatusT
};

function isRemoved(v) {
  var match = v.listingStatus;
  if (typeof match !== "object" && match === "Removed") {
    return true;
  } else {
    return false;
  }
}

function decode$5(obj) {
  var partial_arg = GraphQLConverter.Helpers.someOk(obj.expiryInfo, "expiryInfo was None");
  var partial_arg$1 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeOption((function (i) {
              return i;
            }), obj.rentalStatusChangeAt));
  var partial_arg$2 = GraphQLConverter.Helpers.someOk(obj.broadcastAt, "broadcastAt was None");
  var partial_arg$3 = GraphQLConverter.Helpers.someOk(obj.updatedAt, "updatedAt was None");
  var partial_arg$4 = GraphQLConverter.Helpers.someOk(obj.createdAt, "createdAt was None");
  var partial_arg$5 = GraphQLConverter.Helpers.some(obj.rentalStatus)("propertyStatus was None");
  var partial_arg$6 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$5, param);
  };
  var partial_arg$7 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$6, partial_arg$4, param);
  };
  var partial_arg$8 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$7, partial_arg$3, param);
  };
  var partial_arg$9 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$8, partial_arg$2, param);
  };
  var partial_arg$10 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$9, partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (status, createdAt, updatedAt, broadcastAt, statusChangeAt, expiryInfo) {
                return {
                        listingStatus: make(status, expiryInfo),
                        createdAt: createdAt,
                        broadcastAt: broadcastAt,
                        updatedAt: updatedAt,
                        statusChange: statusChangeAt !== undefined ? ({
                              TAG: "StatusChange",
                              _0: {
                                changeAt: Caml_option.valFromOption(statusChangeAt),
                                comment: obj.statusChangeComment
                              }
                            }) : "NoChange",
                        expiryInfo: expiryInfo
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$10, partial_arg, param);
            });
}

var ppx_printed_query$7 = "fragment rentalStatus on RentalProperty   {\nrentalStatus: status  \ncreatedAt  \nupdatedAt  \nbroadcastAt  \nstatusChangeComment  \nrentalStatusChangeAt: statusChangeAt  \nexpiryInfo  {\n..." + ExpiryInfo.Fragment.name + "   \n}\n\n}\n" + ExpiryInfo.Fragment.query;

function parse$7(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "rentalStatus");
    var field_rentalStatus;
    if (value$2 !== undefined && !(value$2 == null)) {
      var tmp$1;
      switch (value$2) {
        case "ACTIVE" :
            tmp$1 = "ACTIVE";
            break;
        case "EXPIRED" :
            tmp$1 = "EXPIRED";
            break;
        case "RENTED" :
            tmp$1 = "RENTED";
            break;
        case "WITHDRAWN" :
            tmp$1 = "WITHDRAWN";
            break;
        default:
          tmp$1 = "REMOVED";
      }
      field_rentalStatus = tmp$1;
    } else {
      field_rentalStatus = undefined;
    }
    var value$3 = Js_dict.get(value$1, "createdAt");
    var field_createdAt = value$3 !== undefined ? Core__Option.map(!(value$3 == null) ? value$3 : undefined, GraphQLConverter.Helpers.parseUTCDateFromFloat) : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$4 = Js_dict.get(value$1, "updatedAt");
    var field_updatedAt = value$4 !== undefined ? Core__Option.map(!(value$4 == null) ? value$4 : undefined, GraphQLConverter.Helpers.parseUTCDateFromFloat) : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$5 = Js_dict.get(value$1, "broadcastAt");
    var field_broadcastAt = value$5 !== undefined ? Core__Option.map(!(value$5 == null) ? value$5 : undefined, GraphQLConverter.Helpers.parseUTCDateFromFloat) : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$6 = Js_dict.get(value$1, "statusChangeComment");
    var field_statusChangeComment = value$6 !== undefined && !(value$6 == null) ? value$6 : undefined;
    var value$7 = Js_dict.get(value$1, "rentalStatusChangeAt");
    var field_rentalStatusChangeAt = value$7 !== undefined ? Core__Option.map(!(value$7 == null) ? value$7 : undefined, GraphQLConverter.Helpers.parseUTCDateFromFloat) : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$8 = Js_dict.get(value$1, "expiryInfo");
    var field_expiryInfo = value$8 !== undefined && !(value$8 == null) ? ExpiryInfo.Fragment.parse(value$8) : undefined;
    tmp = {
      createdAt: field_createdAt,
      updatedAt: field_updatedAt,
      broadcastAt: field_broadcastAt,
      rentalStatusChangeAt: field_rentalStatusChangeAt,
      expiryInfo: field_expiryInfo,
      rentalStatus: field_rentalStatus,
      statusChangeComment: field_statusChangeComment
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$5(tmp);
}

var name$5 = "rentalStatus";

function ret_type$7(f) {
  return {};
}

var MT_Ret$7 = {};

var RentalStatus = {
  ppx_printed_query: ppx_printed_query$7,
  query: ppx_printed_query$7,
  parse: parse$7,
  name: name$5,
  ret_type: ret_type$7,
  MT_Ret: MT_Ret$7
};

var Status = {
  ListingStatus: ListingStatus,
  isRemoved: isRemoved,
  decode: decode$5,
  RentalStatus: RentalStatus,
  Fragment: undefined
};

function decode$6(obj) {
  var match = obj.mlsListingType;
  var match$1 = obj.rentalOnMlsAt;
  if (match !== undefined) {
    if (match === "COMING_SOON") {
      if (match$1 !== undefined && match$1.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  TAG: "ComingSoon",
                  _0: match$1._0
                }
              };
      } else {
        return {
                TAG: "Error",
                _0: GraphQLConverter.Helpers.makeError("error decoding onMlsDate for ACTIVE | COMING_SOON")
              };
      }
    } else if (match === "OFF_MLS") {
      return {
              TAG: "Ok",
              _0: "OffMLS"
            };
    } else if (match$1 !== undefined && match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                TAG: "Active",
                _0: match$1._0
              }
            };
    } else {
      return {
              TAG: "Error",
              _0: GraphQLConverter.Helpers.makeError("error decoding onMlsDate for ACTIVE | COMING_SOON")
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: GraphQLConverter.Helpers.makeError("mlsListingType was None")
          };
  }
}

var ppx_printed_query$8 = "fragment rentalMLSPlans on RentalProperty   {\nmlsListingType  \nrentalOnMlsAt: onMlsAt  \n}\n";

function parse$8(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "mlsListingType");
    var field_mlsListingType;
    if (value$2 !== undefined && !(value$2 == null)) {
      var tmp$1;
      switch (value$2) {
        case "ACTIVE" :
            tmp$1 = "ACTIVE";
            break;
        case "COMING_SOON" :
            tmp$1 = "COMING_SOON";
            break;
        default:
          tmp$1 = "OFF_MLS";
      }
      field_mlsListingType = tmp$1;
    } else {
      field_mlsListingType = undefined;
    }
    var value$3 = Js_dict.get(value$1, "rentalOnMlsAt");
    var field_rentalOnMlsAt = value$3 !== undefined ? Core__Option.map(!(value$3 == null) ? value$3 : undefined, GraphQLConverter.Helpers.parseUTCDateFromFloat) : Js_exn.raiseError("Unexpected GraphQL query response");
    tmp = {
      rentalOnMlsAt: field_rentalOnMlsAt,
      mlsListingType: field_mlsListingType
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$6(tmp);
}

var name$6 = "rentalMLSPlans";

function ret_type$8(f) {
  return {};
}

var MT_Ret$8 = {};

var RentalMLSPlans = {
  ppx_printed_query: ppx_printed_query$8,
  query: ppx_printed_query$8,
  parse: parse$8,
  name: name$6,
  ret_type: ret_type$8,
  MT_Ret: MT_Ret$8
};

var RentalMLSPlans$1 = {
  decode: decode$6,
  RentalMLSPlans: RentalMLSPlans,
  Fragment: undefined
};

function decode$7(o) {
  return GraphQLConverter.Helpers.decodeRequired("images was None", (function (param) {
                return GraphQLConverter.Helpers.decodeArray((function (param) {
                              return GraphQLConverter.Helpers.decodeRequired("image was None", Types__Attachment.decode, param);
                            }), param);
              }), o.images);
}

var ppx_printed_query$9 = "fragment RentalProperty__Images on RentalProperty   {\nimages  {\n..." + Types__Attachment.Fragment.Attachment.name + "   \n}\n\n}\n" + Types__Attachment.Fragment.Attachment.query;

function parse$9(value) {
  var value$1 = value["images"];
  return decode$7({
              images: !(value$1 == null) ? Js_array.map((function (value) {
                        if (!(value == null)) {
                          return Curry._1(Types__Attachment.Fragment.Attachment.parse, value);
                        }
                        
                      }), value$1) : undefined
            });
}

var name$7 = "RentalProperty__Images";

function ret_type$9(f) {
  return {};
}

var MT_Ret$9 = {};

var RentalProperty__Images = {
  ppx_printed_query: ppx_printed_query$9,
  query: ppx_printed_query$9,
  parse: parse$9,
  name: name$7,
  ret_type: ret_type$9,
  MT_Ret: MT_Ret$9
};

var Images = {
  decode: decode$7,
  RentalProperty__Images: RentalProperty__Images
};

function decode$8(obj) {
  return GraphQLConverter.Helpers.decodeRequired("chapter was None", (function (chapter) {
                return GraphQLConverter.Helpers.decodeRequired("timeZone was None", (function (v) {
                              return {
                                      TAG: "Ok",
                                      _0: DateFnsTz.Timezone.make(v)
                                    };
                            }), chapter.timeZone);
              }), obj.chapter);
}

var ppx_printed_query$10 = "fragment RentalPropertyTimeZone on RentalProperty   {\nid  \nchapter  {\nid  \ntimeZone  \n}\n\n}\n";

function parse$10(value) {
  var value$1 = value["chapter"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["timeZone"];
    tmp = {
      id: value$1["id"],
      timeZone: !(value$2 == null) ? value$2 : undefined
    };
  }
  return decode$8({
              id: value["id"],
              chapter: tmp
            });
}

var name$8 = "RentalPropertyTimeZone";

function ret_type$10(f) {
  return {};
}

var MT_Ret$10 = {};

var RentalPropertyTimeZone = {
  ppx_printed_query: ppx_printed_query$10,
  query: ppx_printed_query$10,
  parse: parse$10,
  name: name$8,
  ret_type: ret_type$10,
  MT_Ret: MT_Ret$10
};

var TimeZone = {
  decode: decode$8,
  RentalPropertyTimeZone: RentalPropertyTimeZone,
  Fragment: undefined
};

function make$1(id, address, $$location, attributes, price, lease, status, mlsPlans, activeOnMls, description, timeZone, images, coordinates) {
  return {
          id: id,
          address: address,
          location: $$location,
          attributes: attributes,
          price: price,
          lease: lease,
          status: status,
          mlsPlans: mlsPlans,
          activeOnMls: activeOnMls,
          description: description,
          timeZone: timeZone,
          images: images,
          coordinates: coordinates
        };
}

function decode$9(obj) {
  var partial_arg = obj.id;
  var partial_arg$1 = GraphQLConverter.Helpers.someOk(obj.coordinates, "coordinates was None");
  var partial_arg$2 = GraphQLConverter.Helpers.ok(obj.images);
  var partial_arg$3 = GraphQLConverter.Helpers.ok(obj.timeZone);
  var partial_arg$4 = GraphQLConverter.Helpers.some(obj.description)("description was None");
  var partial_arg$5 = GraphQLConverter.Helpers.some(obj.activeOnMls)("activeOnMls was None");
  var partial_arg$6 = GraphQLConverter.Helpers.ok(obj.mlsPlans);
  var partial_arg$7 = GraphQLConverter.Helpers.ok(obj.status);
  var partial_arg$8 = GraphQLConverter.Helpers.ok(obj.lease);
  var partial_arg$9 = GraphQLConverter.Helpers.ok(obj.price);
  var partial_arg$10 = GraphQLConverter.Helpers.ok(obj.attributes);
  var partial_arg$11 = GraphQLConverter.Helpers.ok(obj.location);
  var partial_arg$12 = GraphQLConverter.Helpers.ok(obj.address);
  var partial_arg$13 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$12, param);
  };
  var partial_arg$14 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$13, partial_arg$11, param);
  };
  var partial_arg$15 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$14, partial_arg$10, param);
  };
  var partial_arg$16 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$15, partial_arg$9, param);
  };
  var partial_arg$17 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$16, partial_arg$8, param);
  };
  var partial_arg$18 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$17, partial_arg$7, param);
  };
  var partial_arg$19 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$18, partial_arg$6, param);
  };
  var partial_arg$20 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$19, partial_arg$5, param);
  };
  var partial_arg$21 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$20, partial_arg$4, param);
  };
  var partial_arg$22 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$21, partial_arg$3, param);
  };
  var partial_arg$23 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$22, partial_arg$2, param);
  };
  return GraphQLConverter.Helpers.run(function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11) {
                return make$1(partial_arg, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11);
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$23, partial_arg$1, param);
            });
}

var ppx_printed_query$11 = "fragment BasicRentalProperty__RentalProperty on RentalProperty   {\nid  \n...rentalAddress   \n...RentalDisplayLocation   \n...RentalAttributes   \n...rentalPrice   \n...lease   \n...rentalStatus   \n...rentalMLSPlans   \ndescription  \nactiveOnMls  \n...RentalProperty__Images   \n...RentalPropertyTimeZone   \ncoordinates  {\n..." + Coordinates.Coordinates.name + "   \n}\n\n}\n" + Coordinates.Coordinates.query + ppx_printed_query$9 + ppx_printed_query$4 + ppx_printed_query$3 + ppx_printed_query$2 + ppx_printed_query$6 + ppx_printed_query$5 + ppx_printed_query$8 + ppx_printed_query$7 + ppx_printed_query$10;

function parse$11(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "id");
    var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$3 = Js_dict.get(value$1, "description");
    var field_description = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    var value$4 = Js_dict.get(value$1, "activeOnMls");
    var field_activeOnMls = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
    var value$5 = Js_dict.get(value$1, "coordinates");
    var field_coordinates = value$5 !== undefined && !(value$5 == null) ? Coordinates.Coordinates.parse(value$5) : undefined;
    tmp = {
      id: field_id,
      address: parse$2(value$1),
      location: parse$5(value$1),
      attributes: parse$6(value$1),
      price: parse$3(value$1),
      lease: parse$4(value$1),
      status: parse$7(value$1),
      mlsPlans: parse$8(value$1),
      activeOnMls: field_activeOnMls,
      description: field_description,
      images: parse$9(value$1),
      timeZone: parse$10(value$1),
      coordinates: field_coordinates
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$9(tmp);
}

function ret_type$11(f) {
  return {};
}

var MT_Ret$11 = {};

var BasicRentalProperty__RentalProperty = {
  ppx_printed_query: ppx_printed_query$11,
  query: ppx_printed_query$11,
  parse: parse$11,
  name: "BasicRentalProperty__RentalProperty",
  ret_type: ret_type$11,
  MT_Ret: MT_Ret$11
};

var F = {
  BasicRentalProperty__RentalProperty: BasicRentalProperty__RentalProperty
};

var Basic = {
  make: make$1,
  decode: decode$9,
  F: F,
  Fragment: undefined
};

function decode$10(obj) {
  var primaryContact = Core__Option.flatMap(Core__Option.flatMap(obj.client, (function (client) {
              return client.primaryContact;
            })), (function (contact) {
          return Types__Clients__Contact.name_of_contact(contact.firstName, contact.lastName);
        }));
  return Core__Option.getOr(primaryContact, Core__Option.mapOr(obj.address, "Seller", (function (address) {
                    return "Seller at " + address;
                  })));
}

var ppx_printed_query$12 = "fragment RentalPropertyTitle on RentalProperty   {\nid  \naddress  \nclient  {\nid  \nprimaryContact  {\nid  \nfirstName  \nlastName  \n}\n\n}\n\n}\n";

function parse$12(value) {
  var value$1 = value["address"];
  var value$2 = value["client"];
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2["primaryContact"];
    var tmp$1;
    if (value$3 == null) {
      tmp$1 = undefined;
    } else {
      var value$4 = value$3["firstName"];
      var value$5 = value$3["lastName"];
      tmp$1 = {
        id: value$3["id"],
        firstName: !(value$4 == null) ? value$4 : undefined,
        lastName: !(value$5 == null) ? value$5 : undefined
      };
    }
    tmp = {
      id: value$2["id"],
      primaryContact: tmp$1
    };
  }
  return decode$10({
              id: value["id"],
              address: !(value$1 == null) ? value$1 : undefined,
              client: tmp
            });
}

function ret_type$12(f) {
  return {};
}

var MT_Ret$12 = {};

var RentalPropertyTitle = {
  ppx_printed_query: ppx_printed_query$12,
  query: ppx_printed_query$12,
  parse: parse$12,
  name: "RentalPropertyTitle",
  ret_type: ret_type$12,
  MT_Ret: MT_Ret$12
};

var RentalPropertyTitle$1 = {
  decode: decode$10,
  RentalPropertyTitle: RentalPropertyTitle,
  Fragment: undefined
};

exports.decodeOptionalUTCDate = decodeOptionalUTCDate;
exports.decodeOptionalCalendarDate = decodeOptionalCalendarDate;
exports.Messages = Messages;
exports.RentalAddress = RentalAddress$1;
exports.Price = Price;
exports.Lease = Lease$1;
exports.RentalLocation = RentalLocation;
exports.RentalAttributes = RentalAttributes$1;
exports.Status = Status;
exports.RentalMLSPlans = RentalMLSPlans$1;
exports.Images = Images;
exports.TimeZone = TimeZone;
exports.Basic = Basic;
exports.RentalPropertyTitle = RentalPropertyTitle$1;
/* Property Not a pure module */
