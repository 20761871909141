// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var GraphQLConverter = require("../../common/GraphQLConverter.bs.js");

function decode(x) {
  var partial_arg = GraphQLConverter.Helpers.some(x.lon)("lon was None");
  var partial_arg$1 = GraphQLConverter.Helpers.some(x.lat)("lat was None");
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(x, param);
                }), partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (lat, lon) {
                return {
                        lat: lat,
                        lon: lon
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
            });
}

var ppx_printed_query = "fragment Coordinates on Coordinates   {\nlat  \nlon  \n}\n";

function parse(value) {
  var value$1 = value["lat"];
  var value$2 = value["lon"];
  return decode({
              lat: !(value$1 == null) ? value$1 : undefined,
              lon: !(value$2 == null) ? value$2 : undefined
            });
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var Coordinates = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "Coordinates",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment;

exports.decode = decode;
exports.Coordinates = Coordinates;
exports.Fragment = Fragment;
/* GraphQLConverter Not a pure module */
